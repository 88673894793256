<!-- MyTabs.vue -->
<template>
  <div>
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        class="tab"
        :class="{ 'active-0': current_tab_.name === tab.name && (scroll_top >=1 || current_tab_.color === '黑'), 'active-1': scroll_top <1 && current_tab_.name === tab.name && current_tab_.color === '白',
        'color-0': (current_tab_.color === '白' && scroll_top >=1) || current_tab_.color === '黑', 'color-1': scroll_top <1 && current_tab_.color === '白' }"
        @click="selectTab(tab)"
      >
        {{ tab.label }}
      </div>
    </div>
    <div class="tab-content">
      <slot :name="current_tab_.name"></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits,toRef } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  current_tab_:{
    type: Object,
    required: true,
  },
  scroll_top:{
    type: Number,
    required: true,
  }
});

let current_tab = toRef(props, "current_tab_")
let scroll_top = toRef(props, "scroll_top")
// const props = defineProps({
//   tabs: {
//     type: Array,
//     required: true,
//   },
//   current_tab_:{
//     type: String,
//     required: true,
//   }
// });

// let current_tab = JSON.parse(props.current_tab_)

// 定义一个 emit 函数
const emit = defineEmits(['update-data']);
const selectTab = (tab) => {
  current_tab = tab;
  console.log("------------------current_tab", current_tab)
  emit('update-data', tab);
  window.scrollTo({
      top: 0,
      // behavior: 'smooth' // 添加平滑滚动效果
  });
};

</script>


<style scoped>
.tabs {
  display: flex;
  cursor: pointer;
  padding: 26px 0px ;
  /* border-bottom: 1px solid #000; */
}

.tab {
  padding: 4px 0px ;
  margin: 0px 40px;
  font-size:14px;
}
.color-0 {
  color: rgb(85, 85, 85);
}
.color-1 {
  color: rgb(85, 85, 85);
}
.active-0 {
  font-weight: bold;
  color:rgb(51, 102, 255);
}
.active-1{
  font-weight: bold;
  color:rgb(51, 102, 255);
}

/* .tab-content {
  padding: 20px;
} */

</style>

