<template>
  <router-view/>
  <!-- <MainPage/> -->
</template>

<script>
// import MainPage from './views/MainPage.vue'

export default {
  name: 'App',
  // components: {
  //   MainPage
  // }
}

</script>

<style>
#app {
  font-family: Arial, Avenir, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
