<template>
  <div id="d" style="text-align: center">本窗口将在{{ i }}秒后自动关闭!</div>
</template>
<script>
export default {
  data() {
    return {
      i: 5,
      time: null,
    };
  },
  mounted() {
    this.setItem();
    this.i = 3;
    this.time = setInterval(() => {
      this.i -= 1;
      if (this.i < 1) {
        clearTimeout(this.time);
        this.time = null;
        this.i = 3;
        this.openUrl();
      }
    }, 1000);
  },
  methods: {
    setItem() {
      var spapi_oauth_code = this.GetUrlParam("spapi_oauth_code");
      var selling_partner_id = this.GetUrlParam("selling_partner_id");
      window.localStorage.setItem("spapi_oauth_code", spapi_oauth_code);
      window.localStorage.setItem("selling_partner_id", selling_partner_id);
    },

    openUrl() {
      var spapi_oauth_code = window.localStorage.getItem("spapi_oauth_code");
      var selling_partner_id =
        window.localStorage.getItem("selling_partner_id");
      window.location = `https://www.zccang.com/#/success?spapi_oauth_code=${spapi_oauth_code}&selling_partner_id=${selling_partner_id}`;
    },

    GetUrlParam(paraName) {
      //获取url的参数   GetUrlParam(要获取的参数名)
      var url = window.location.href;
      // console.log("window.location.href", window.location.href, url);
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
  },
};
</script>
<script></script>
