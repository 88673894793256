import { createApp } from 'vue'
import App from './App.vue'
import './assets/reset.css'
import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css'; 
import 'element-plus/theme-chalk/index.css'; // 只在 Element Plus 版本支持时
import router from '@/router';

const app = createApp(App);
app.use(ElementPlus);
app.use(router);

// 设置全局 CSS 变量
document.documentElement.style.setProperty('--el-color-primary', 'rgb(0,91,245)');

app.mount('#app');