<template>
  <div class="main-feild">
    <div class="header s-0" v-show="curr_tab.color !== '白'">
      <!-- v-show="
        curr_tab.color !== '白' || (curr_tab.color == '白' && scroll_top >= 1)
      " -->
      <div class="index-header">
        <div class="left">
          <div class="nav-logo">
            <img src="@/assets/images/icon/u91.png" alt="Logo" class="logo-0" />
          </div>
          <div class="nav">
            <my-tabs
              :tabs="tabs"
              :current_tab_="curr_tab"
              :scroll_top="scroll_top"
              @update-data="handleUpdateData"
            >
            </my-tabs>
          </div>
        </div>
        <div class="function-area" @click="toLogin">
          <div class="font-0">立即使用</div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="b-0" v-if="curr_tab.name == 'tab1'">
        <div id="b-p-0">
          <div class="top-0 index-back">
            <div class="header s-1" v-show="curr_tab.color == '白'">
              <!-- v-show="curr_tab.color == '白' && scroll_top < 1" -->
              <div class="index-header">
                <div class="left">
                  <div class="nav-logo">
                    <img
                      src="@/assets/images/icon/u91.png"
                      alt="Logo"
                      class="logo-1"
                    />
                  </div>
                  <div class="nav">
                    <my-tabs
                      :tabs="tabs"
                      :current_tab_="curr_tab"
                      :scroll_top="scroll_top"
                      @update-data="handleUpdateData"
                    >
                    </my-tabs>
                  </div>
                </div>

                <div class="function-area" @click="toLogin">
                  <div class="font-1">立即使用</div>
                </div>
              </div>
            </div>
            <!-- 电脑区域 -->
            <div class="computer-container">
              <div class="computer-content">
                <div class="left">
                  <div class="title">亚马逊<span>一站式</span>管理系统</div>
                  <div class="introduce">
                    专注亚马逊跨境电商精细化管理，助力商家高效运营
                  </div>
                  <div class="button-style">
                    <span @click="toLogin">立即使用</span>
                  </div>
                </div>
                <div class="right">
                  <img src="@/assets/img/banner.png" alt="not found" />
                </div>
              </div>
              <!-- 介绍区域 -->
              <div class="introduce-area">
                <!-- 底部 -->
                <div class="navigation-bottom">
                  <div class="bottom-item">
                    <img
                      src="../assets/img/team.png"
                      alt="not"
                      style="width: 73px"
                    />
                    <div class="item-content">
                      <div class="item-text"><span>300 </span> <span class="unit-text-content">员工</span></div>
                      <div class="item-introduce">专业团队支撑</div>
                    </div>
                  </div>
                  <div class="bottom-item">
                    <img src="../assets/img/experience.png" alt="not" />
                    <div class="item-content">
                      <div class="item-text"><span>10 </span><span class="unit-text-content">余年</span></div>
                      <div class="item-introduce">跨境电商实战经验</div>
                    </div>
                  </div>
                  <div class="bottom-item">
                    <img src="../assets/img/market.png" alt="not" />
                    <div class="item-content">
                      <div class="item-text"><span>15 </span> <span class="unit-text-content">国家</span></div>
                      <div class="item-introduce">业务遍布全球</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 零门廊-导航文字 -->
        <div class="zero-nav-container">
          <div class="middle-nav">
            <div class="title">零门槛低成本建设跨境电商业务</div>
            <div class="introduce">
              <div class="introduce-text">
                从线上进销存管理、用户精细化管理到
                品牌统一管理运营，以跨境电商全链路经验沉淀，搭建完备的跨境电商运营管理平台，
              </div>
              <div class="introduce-text text-two">
                全方位解决商家跨境生意痛点，帮助品牌高效稳定走向全球。
              </div>
            </div>
          </div>
        </div>
        <!-- 卡片 -->
        <div class="card-container">
          <div class="card-content">
            <div class="card-item">
              <img
                src="@/assets/img/erp.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">进销存一体化管理</div>
              <div class="card-text">进销存全链路线上管理，简单高效</div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>集成化管理，统一管理货物全生命周期流程</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>优化资源配置，实时监控库存、销售、采购状况 </span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>订单全流程智能识别自动处理，随时进行人工干预</span>
                </div>
              </div>
            </div>
            <div class="card-item">
              <img
                src="@/assets/img/data.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">多维数据报表</div>
              <div class="card-text">更全面的数据报表，更好的可视化呈现</div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>全时段数据驾驶舱</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>全方位BI分析报表</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>数据赋能决策</span>
                </div>
              </div>
            </div>
            <div class="card-item">
              <img
                src="@/assets/img/brand.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">品牌精细管理</div>
              <div class="card-text">
                搭建品牌专属店铺，多渠道传播，安全高效
              </div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>打造品牌内容，提升品牌调性</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>品牌分析、精细化运营</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>数据独立、安全，部署在云端</span>
                </div>
              </div>
            </div>
            <div class="card-item">
              <img
                src="@/assets/img/money.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">财务精准管理</div>
              <div class="card-text">财务实时跟踪，自动体现，业财一体化</div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>成本利润实时查看</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>成本核算实时控制</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>全链路资金追踪</span>
                </div>
              </div>
            </div>
            <div class="card-item">
              <img
                src="@/assets/img/ads.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">全流程广告管理</div>
              <div class="card-text">
                投放、投中使、投后全链路管理与经验沉淀
              </div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>投放前：挖优质词定策略</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>投放中：自动规则提效，AI助力规则</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>投放后：精准透视分析</span>
                </div>
              </div>
            </div>
            <div class="card-item">
              <img
                src="@/assets/img/tools.png"
                alt="not found"
                class="item-icon"
              />
              <div class="card-title">高效运营工具</div>
              <div class="card-text">
                系统监控，自然语言大模型，助力运营决策
              </div>
              <div class="item-content">
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>自动化规则，让系统代替人工操作</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>AI人工智能，让策略智能化</span>
                </div>
                <div class="item">
                  <img src="@/assets/img/checkIcon.png" alt="not found" />
                  <span>多场景小工具，辅助运营提升效率</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div id="b-p-2">
          <div class="center-title">
            <div class="center1-top">核心功能</div>
            <div class="center1-top1">
              打通跨境电商业务全流程，闭环管理业务，实现业绩增长
            </div>
          </div>
          <div class="center1-1">
            <ul class="center1-header">
              <li>
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img
                        class="u19_img"
                        src="@/assets/images/intro/first.png"
                      />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none">品牌精细管理</span>
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >确定品牌定位形象，丰富品牌内容，多渠道品牌传播，助力品牌精细化营销</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img
                        class="u19_img"
                        src="@/assets/images/intro/second.png"
                      />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none"
                          >进销存一体管理</span
                        >
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >打通订单、采购、发货、物流等环节，提供智能提供补货建议</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img
                        class="u19_img"
                        src="@/assets/images/intro/three.png"
                      />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none"
                          >多维度数据报表</span
                        >
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >自动形成利润报表、采购报表、库存报表、业绩报表等，数据可视化</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li class="margin-0">
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img class="u19_img" src="@/assets/images/intro/b.png" />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none">财务精准管理</span>
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >每日实时利润统计，订单级回款跟踪，自动提现，业财一体化管理</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li class="margin-0">
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img class="u19_img" src="@/assets/images/intro/c.png" />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none"
                          >全流程广告管理</span
                        >
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >投放前挖掘优质词，投中使用自动规则提效，投后溯源&分析广告转化率</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li class="margin-0">
                <div
                  class="u17"
                  data-left="325"
                  data-top="1867"
                  data-width="307"
                  data-height="229"
                >
                  <div class="u18 ax_default _文本段落">
                    <div class="u18_div">
                      <img class="u19_img" src="@/assets/images/intro/d.png" />
                    </div>
                    <div class="text">
                      <p style="font-size: 30px">
                        <span style="text-decoration: none">高效运营工具</span>
                      </p>
                      <p style="font-size: 12px">
                        <span style="text-decoration: none"><br /></span>
                      </p>
                      <p style="font-size: 14px">
                        <span style="text-decoration: none"
                          >实时监控关键词排名、竞品监控、ChatGPT文案等工具，辅助运营决策</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
        <!-- 亚马逊全链路数据 -->
        <div class="link-area">
          <div class="link-content">
            <div class="link-title">亚马逊全链路数据</div>
            <div class="link-introduce">
              涵盖亚马逊销售、物流、商品、广告、流量等全链路数据
            </div>
            <img src="@/assets/img/dataana.png" alt="not found" />
          </div>
        </div>
        <!-- 提供的服务 -->
        <div class="service-area">
          <div class="title">我们提供的服务</div>
          <div class="tip">打造最佳服务矩阵，全方位、多角度服务客户</div>
          <div class="card-content">
            <div class="top-card">
              <div class="card-item">
                <div class="item-title">24小时人工在线</div>
                <div class="item-tip">确保问题都有回应</div>
                <img src="../assets/img/customerService.png" alt="not" />
              </div>
              <div class="card-item">
                <div class="item-title">系统订制服务</div>
                <div class="item-tip">个性化业务适配</div>
                <img src="../assets/img/system.png" alt="not" />
              </div>
            </div>
            <div class="bottom-card">
              <div class="card-bottom-item">
                <div class="item-title">即开即用</div>
                <div class="item-tip">SAAS化服务</div>
                <img src="../assets/img/saas.png" alt="not" />
              </div>
              <div class="card-bottom-item">
                <div class="item-title">数据隔离</div>
                <div class="item-tip">确保数据安全稳定</div>
                <img src="../assets/img/dataIsolation.png" alt="not" />
              </div>
              <div class="card-bottom-item">
                <div class="item-title">商务咨询</div>
                <div class="item-tip">专业咨询赋能客户</div>
                <img src="../assets/img/businessAsk.png" alt="not" />
              </div>
            </div>
          </div>
        </div>
        <!-- 结尾介绍 -->
        <div class="end-introduce">
          <div class="text-content">
            <div class="text-top">想要实现业务增长？就用顺衡ERP</div>
            <div class="button-content">
              <div class="button-style" @click="gotoLogin">立即体验</div>
            </div>
          </div>
        </div>
        <!-- <div id="b-p-1">
          <div id="b-part-1">
            <div class="center-title">
              <div class="center1-top">货品进销存，一个ERP全搞定</div>
              <div class="center1-top1">
                快速开启、自动同步数据、打通全渠道管理
              </div>
            </div>
            <div class="center1-1">
              <ul class="center1-header">
                <li class="fist">
                  <img
                    src="@/assets/images/intro/shop.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>快速开店，免费绑定店铺</div>
                </li>
                <div class="jiantou">
                  <img src="@/assets/images/intro/arrows.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/order-capture.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>系统获取订单</div>
                </li>
                <div class="jiantou">
                  <img src="@/assets/images/intro/arrows.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/review-order.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>自动审核订单</div>
                </li>
                <div class="jiantou">
                  <img src="@/assets/images/intro/arrows.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/order-dispatching.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>一键分组分派订单</div>
                </li>
                <div class="jiantou2">
                  <img src="@/assets/images/intro/arrows2.png" alt="" />
                </div>
                <li style="margin-left: 65px">
                  <img
                    src="@/assets/images/intro/print-shipping.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>订单打印、发货</div>
                </li>
                <div class="jiantou" style="margin-left: 20px">
                  <img src="@/assets/images/intro/arrows3.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/shipment-order.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>生成发货订单</div>
                </li>
                <div class="jiantou">
                  <img src="@/assets/images/intro/arrows3.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/check-order.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>审核校验订单</div>
                </li>
                <div class="jiantou">
                  <img src="@/assets/images/intro/arrows3.png" alt="" />
                </div>
                <li>
                  <img
                    src="@/assets/images/intro/logistics-distribution.png"
                    alt=""
                    class="liu-img"
                  />
                  <div>仓库物流分配</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="center3">
          <div class="center3-top">我们的优势是什么？</div>
          <div class="center3-top1">安全高效，简单便捷</div>
          <ul class="center3-center">
            <li>
              <div class="center3-img">
                <img src="@/assets/images/intro/group48.png" alt="" />
              </div>
              <div class="center3-p">
                <p class="center3-p1">灵活控制订单流程</p>
                <p>订单发货流程自由控制，用户可根据自己的需</p>
                <p>要配置流程，发货环节灵活简便。</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group49.png" alt="" />
              </div>
              <div class="center3-p">
                <p class="center3-p1">成本更可控</p>
                <p>人力成本</p>
                <p>人员流动带来的高额培训成本</p>
                <p>系统成本</p>
                <p>SaaS模式节省80%的系统开发成本</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group50.png" alt="" />
              </div>
              <div class="center3-p">
                <p class="center3-p1">操作更简单</p>
                <p>人性化设计</p>
                <p>根据跨境卖家日常设计系统流程</p>
                <p>智能化处理</p>
                <p>节省80%重复工作的时间</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/group51.png" alt="" />
              </div>
              <div class="center3-p">
                <p class="center3-p1">管理更顺畅</p>
                <p>权限管理，规范化每个环节</p>
                <p>运营流程，更加合规、畅通</p>
              </div>
            </li>
            <li class="mar-0">
              <div class="center3-img">
                <img src="@/assets/images/intro/data.png" alt="" />
              </div>
              <div class="center3-p">
                <p class="center3-p1">数据更安全</p>
                <p>自研亿级调度系统，运行稳定且快速</p>
                <p>
                  数据加密处理，定期漏洞检测，不分享不售卖，从源头保证数据安全，绝不外泄！
                </p>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
      <!-- 产品 -->
      <div class="b-0" v-if="curr_tab.name == 'tab2'">
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-2 product-list">
              <h2 class="title-2"><span>品牌精细管理</span></h2>
              <div class="product-tip">
                确定品牌定位形象，通过对自有品牌的全面细致分析，借助多渠道进行品牌传播，助力品牌精细化运营。
              </div>
              <ul class="font-2">
                <div
                  class="product-item"
                  v-for="item in productContent.brand"
                  :key="item.id"
                >
                  <img src="@/assets/img/complete.png" alt="not" />
                  <span>{{ item.text }}</span>
                </div>
              </ul>
            </div>
            <div class="s-right">
              <img class="u19_img0" src="@/assets/img/brandbanner.png" />
            </div>
          </div>
        </div>
        <div class="b-p-4">
          <div class="s-body">
            <div class="s-left left-top-1">
              <img class="u19_img0" src="@/assets/img/erpbanner.png" />
            </div>
            <div class="s-right product-list">
              <h2 class="title-2"><span>进销存一体化</span></h2>
              <div class="product-tip">
                智驭进销存，精准管理采购、销售与库存，数据驱动决策，优化成本结构，提升业务响应速度。
              </div>
              <ul class="font-2">
                <div
                  class="product-item"
                  v-for="item in productContent.purchase"
                  :key="item.id"
                >
                  <img src="@/assets/img/complete.png" alt="not" />
                  <span>{{ item.text }}</span>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-1 product-list">
              <h2 class="title-2"><span>财务精准控制</span></h2>
              <div class="product-tip">
                智慧管理、优化成本，以数据为眼、效率为翼，打造透明高效的财务管理体系。
              </div>
              <ul class="font-2">
                <div
                  class="product-item"
                  v-for="item in productContent.finance"
                  :key="item.id"
                >
                  <img src="@/assets/img/complete.png" alt="not" />
                  <span>{{ item.text }}</span>
                </div>
              </ul>
            </div>
            <div class="s-right">
              <img class="u19_img" src="@/assets/img/moneybanner.png" />
            </div>
          </div>
        </div>
        <div class="b-p-4">
          <div class="s-body">
            <div class="s-left left-top-1">
              <img class="u19_img" src="@/assets/img/databanner.png" />
            </div>
            <div class="s-right product-list">
              <h2 class="title-2"><span>全面数据报表</span></h2>
              <div class="product-tip">
                数据领航，全方位解析业务脉络。精准呈现，一目了然，赋能决策，驱动增长
              </div>
              <ul class="font-2">
                <div
                  class="product-item"
                  v-for="item in productContent.purchase"
                  :key="item.id"
                >
                  <img src="@/assets/img/complete.png" alt="not" />
                  <span>{{ item.text }}</span>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="b-p-3">
          <div class="s-body">
            <div class="s-left left-top-1 product-list">
              <h2 class="title-2"><span>全流程广告管理</span></h2>
              <div class="product-tip">
                智能策略，精准触达。一站式管理，数据洞察，解锁全球商机，驱动销量飙升。
              </div>
              <ul class="font-2">
                <div
                  class="product-item"
                  v-for="item in productContent.allProcess"
                  :key="item.id"
                >
                  <img src="@/assets/img/complete.png" alt="not" />
                  <span>{{ item.text }}</span>
                </div>
              </ul>
            </div>
            <div class="s-right">
              <img
                class="u19_img"
                src="@/assets/images/intro/9dad0ebbfacce4596312729410020888.png"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 价格 -->
      <div class="b-0" v-if="curr_tab.name == 'tab3'">
        <!-- 价格区域 -->
        <div class="price-area">
          <div class="select-container">
            <div class="title">三个版本，满足您的多样化选择</div>
            <div class="tip">不同版本适用不同规模企业</div>
            <div class="card-content">
              <div class="card-item">
                <div class="title">免费版</div>
                <div class="img-content">
                  <img src="@/assets/img/grey.png" alt="not" class="item-img" />
                </div>
                <div class="month">免费试用1个月</div>
                <div class="content">
                  <div class="content-item">店铺数量：1家</div>
                  <div class="content-item">操作人数：5个</div>
                  <div class="content-item">订单量：500单/日</div>
                  <div class="content-item">
                    采购管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    订单管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    物流管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    数智化管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    智能广告：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    财务管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    服务支持：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    独立数据库：<img src="@/assets/img/no.png" alt="not" />
                  </div>
                  <div class="content-item">
                    私人订制：<img src="@/assets/img/no.png" alt="not" />
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="title">基础版</div>
                <div class="img-content">
                  <img src="@/assets/img/red.png" alt="not" class="item-img" />
                </div>
                <div class="month">
                  <span class="month-currency">￥</span
                  ><span class="month-price">5000</span
                  ><span class="month-unit">/年</span>
                </div>
                <div class="content">
                  <div class="content-item">店铺数量：100家</div>
                  <div class="content-item">操作人数：1000个</div>
                  <div class="content-item">订单量：不限</div>
                  <div class="content-item">
                    采购管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    订单管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    物流管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    数智化管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    智能广告：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    财务管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    服务支持：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    独立数据库：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    私人订制：<img src="@/assets/img/no.png" alt="not" />
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="title">进阶版</div>
                <div class="img-content">
                  <img src="@/assets/img/red.png" alt="not" class="item-img" />
                </div>
                <div class="month">
                  <span class="month-currency">￥</span
                  ><span class="month-price">8000</span
                  ><span class="month-unit">/年</span>
                </div>
                <div class="content">
                  <div class="content-item">店铺数量：不限</div>
                  <div class="content-item">操作人数：不限</div>
                  <div class="content-item">订单量：不限</div>
                  <div class="content-item">
                    采购管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    订单管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    物流管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    数智化管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    智能广告：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    财务管理：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    服务支持：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">
                    独立数据库：<img src="@/assets/img/yes.png" alt="not" />
                  </div>
                  <div class="content-item">私人订制：面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 常见问题 -->
        <div class="problem-area">
          <div class="title">常见问题解答</div>
          <div class="content">
            <!-- 上边 -->
            <div class="top">
              <div class="left-one">
                <div class="one-title">1.可以开具发票吗？</div>
                <div class="one-container">
                  可以。但由于每月开票数量限制，需开具发票的客户请提前申请，以免由于公司当月开票量太大，发票限额用尽，耽误或延迟为您开票。公司开具的增值税（电子或纸质）普通发票的货物或应税劳务、服务名称一栏填写的是*软件*顺衡ERP系统软件，开出的发票金额均为含税价格，税率13%。
                </div>
              </div>
              <div class="right-two">
                <div class="two-title">2.如何进行试用或者购买呢？</div>
                <div class="two-container">
                  顺衡ERP系统均需要进行线下签订合同购买，您可直接联系底部的联系方式，我们将会安排专人进行对接。
                </div>
              </div>
            </div>
            <!-- 底部 -->
            <div class="bottom">
              <div class="left-three">
                <div class="three-title">
                  3.付费/试用版本到期以后还能继续使用吗？
                </div>
                <div class="three-container">
                  当您购买的付费版本到期后，您可以选择续费，正常使用付费功能。如果您不付费或者免费版到期后，系统将禁止登录，无法继续使用。
                </div>
              </div>
              <div class="right-four">
                <div class="four-title">4.数据都在顺衡ERP上，是否安全？</div>
                <div class="four-container">
                  安全的。顺衡与专业安全公司合作，定期监测漏洞；同时顺衡会与客户签订数据安全合同，保证不将数据用于商业化目的；针对进阶版客户，还提供私有化部署方案，从源头上保证数据安全。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="b-0" v-if="curr_tab.name == 'tab4'">
        <div class="abt">
          <div class="banner">
            <div class="banner-bg" :style="computedStyle_3">
              <!-- <img src="@/assets/images/intro/service-bg.png" alt="" style="width: 100%;height: 460px;"> -->
              <div class="banner-0">
                <div class="text-a">亚马逊跨境电商，用顺衡ERP</div>
                <div class="text-b">
                  以一站式跨境电商运营管理系统，助力卖家轻松实现业务增长
                </div>
              </div>
            </div>
          </div>
          <div class="p-0">
            <div class="box-0 top-0">
              <div class="tit-0">公司简介</div>
              <div class="text-0">
                <p>
                  南京顺衡电子商务有限公司，致力于为跨境电商卖家提供精细化运营和业财一体化的解决方案。
                </p>
                <p>
                  公司在外贸与跨境电商领域深耕近10年，拥有完善的跨境电商综合解决经验。
                </p>
                <p>
                  聚焦亚马逊跨境电商行业，深入研究业务需求和管理痛点，聚焦线下erp线上数字化、广告营销高效化、财务分析精准化、系统数据安全化等方面，
                  专注客户为提供一体化智能解决方案，为跨境电商卖家量身打造了最适合的运营和供应链管理系统。
                </p>
              </div>
            </div>
            <div class="box-0 top-1">
              <div class="tit-0">联系我们</div>
              <div class="text-0">
                <p>邮箱地址：shunhengerp@sina.com</p>
                <p>
                  公司地址：江苏省南京市秦淮区水西门大街2号银都锦创广场3A048
                </p>
              </div>
            </div>
            <div class="img-0 top-2">
              <img
                class="logo-3"
                src="@/assets/images/background/companyImage.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 底部区域 -->
      <div class="bottom-area">
        <div class="bottom-container">
          <!-- 左边 -->
          <div class="left">
            <div class="left-top">
              <img src="../assets/img/introduce-icon.png" alt="not" />
              <div class="left-top-text">亚马逊一站式管理系统</div>
            </div>
            <div class="left-bottom">
              <div>邮箱地址：shunhengerp@sina.com</div>
              <div>
                公司地址：江苏省南京市秦淮区水西门大街2号银都锦创广场3A048
              </div>
            </div>
          </div>
          <!-- 右边 -->
          <div class="right">
            <div class="right-content">
              <div class="right-item" v-for="item in navList" :key="item.id">
                <div
                  class="item-nav"
                  @click="gotoNav(item.nav)"
                  :class="{ activeNav: item.bottomActive }"
                >
                  {{ item.nav }}
                </div>
                <div class="item-content">
                  <div
                    class="item-text"
                    v-for="i in item.navContent"
                    :key="i.id"
                     @click="gotoNav(item.nav)"
                  >
                    {{ i.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import MyTabs from "@/components/CTabs.vue";
import { useRouter } from "vue-router";

import backgroundImage_2 from "@/assets/images/background/service-bg.png";
import backgroundImage_3 from "@/assets/images/background/aboutBack.png";

const router = useRouter();

const tabs = ref([
  { name: "tab1", label: "首页", color: "白" },
  { name: "tab2", label: "产品", color: "黑" },
  { name: "tab3", label: "价格", color: "黑" },
  { name: "tab4", label: "关于我们", color: "黑" },
]);

// 产品的内容
let productContent = ref({
  brand: [
    {
      id: 1,
      text: "打造品牌内容，提升品牌调性",
    },
    {
      id: 2,
      text: "品牌分析、精细化运营",
    },
    {
      id: 3,
      text: "数据独立、安全，部署在云端",
    },
  ],
  purchase: [
    {
      id: 1,
      text: "集成化管理，统一管理货物全生命周期流程",
    },
    {
      id: 2,
      text: "优化资源配置，实时监控库存、销售、采购状况",
    },
    {
      id: 3,
      text: "订单全流程智能识别自动处理，随时进行人工干预",
    },
  ],
  finance: [
    {
      id: 1,
      text: "成本利润实时查看",
    },
    {
      id: 2,
      text: "成本核算实时控制",
    },
    {
      id: 3,
      text: "全链路资金追踪",
    },
  ],
  allProcess: [
    {
      id: 1,
      text: "投放前：挖优质词定策略",
    },
    {
      id: 2,
      text: "投放中：自动规则提效",
    },
    {
      id: 3,
      text: "投放后：精准透视分析",
    },
  ],
});
// 底部导航
let navList = ref([
  {
    id: 1,
    nav: "首页",
    bottomActive: true,
    navContent: [
      {
        id: 11,
        content: "首页",
      },
    ],
  },
  {
    id: 2,
    nav: "产品",
    bottomActive: false,
    navContent: [
      {
        id: 21,
        content: "品牌精细管理",
      },
      {
        id: 22,
        content: "进销存一体化",
      },
      {
        id: 23,
        content: "财务精准控制",
      },
      {
        id: 24,
        content: "全面数据报表",
      },
      {
        id: 25,
        content: "全流程广告管理",
      },
    ],
  },
  {
    id: 3,
    nav: "价格",
    bottomActive: false,
    navContent: [
      {
        id: 31,
        content: "价格",
      },
    ],
  },
  {
    id: 4,
    nav: "关于我们",
    bottomActive: false,
    navContent: [
      {
        id: 41,
        content: "公司简介",
      },
      {
        id: 42,
        content: "联系我们",
      },
    ],
  },
]);
const curr_tab = ref(tabs.value[0]);
setInterval(() => {
  console.log("111", curr_tab.value);
}, 5000);

const handleUpdateData = (data) => {
  // 接收来自子组件的数据
  curr_tab.value = data;
  console.log("---------------------------curr_tab.value", curr_tab.value);
  // top代表头部点击，激活底部介绍区域的active
  gotoNav(curr_tab.value.label, "top");
};

const toLogin = () => {
  router.push("/login");
  console.log("---------------------------router", router);
};

// 顶部高度
const scroll_top = ref(0);
const handleScroll = () => {
  scroll_top.value = document.documentElement.scrollTop; // 更新 scrol_top
};

// 背景样式

const computedStyle_2 = computed(() => {
  return {
    background: `url(${backgroundImage_2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
});

const computedStyle_3 = computed(() => {
  return {
    background: `url(${backgroundImage_3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
});

const gotoLogin = () => {
  router.push("/login");
};

const gotoNav = (nav, bottomClick = "bottom") => {
  switch (nav) {
    case "首页":
      curr_tab.value = tabs.value[0];
      break;
    case "产品":
      curr_tab.value = tabs.value[1];
      break;
    case "价格":
      curr_tab.value = tabs.value[2];
      break;
    case "关于我们":
      curr_tab.value = tabs.value[3];
      break;
  }
  navList.value.forEach((item) => {
    if (item.nav === nav) {
      item.bottomActive = true;
    } else {
      item.bottomActive = false;
    }
  });
  if (bottomClick === "bottom") {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 添加平滑滚动效果
    });
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll); // 添加滚动事件监听器
});
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll); // 移除监听器以避免内存泄漏
});
</script>

<style scoped lang="scss">
.main-feild {
  width: 100%;
  .body {
    width: 100%;
    margin: 0 auto;
    .b-part {
      width: 100%;
      height: 650px;
    }
    .b-0 {
      width: 100%;
    }
    // 零门廊-导航文字
    .zero-nav-container {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #fff;
      margin-top: 120px;
      .middle-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 26px 0 22px 0;
        margin-bottom: 35px;
        margin-top: 30px;
        width: 75%;
        .title {
          font-size: 48px;
          letter-spacing: 2px;
          margin-bottom: 35px;
          color: black;
        }
        .introduce {
          .introduce-text {
            font-size: 20px;
            letter-spacing: 1px;
            color: rgb(102, 102, 102);
            &.text-two {
              display: flex;
              justify-content: center;
              margin-top: 5px;
              color: rgb(102, 102, 102);
            }
          }
        }
      }
    }
    // 卡片
    .card-container {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #fff;
      padding-bottom: 100px;
      .card-content {
        display: flex;
        width: 76%;
        flex-wrap: wrap;
        gap: 40px;
        .card-item {
          display: flex;
          width: calc((100% - 83px) / 3);
          flex-direction: column;
          align-items: flex-start;
          padding: 40px 23px 50px 35px;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
          gap: 25px;
          .item-icon {
            width: 50px;
            height: 48px;
            margin-left: -5px;
          }
          .card-title {
            font-size: 30px;
            letter-spacing: 1px;
            color: #121212;
          }
          .card-text {
            font-size: 16px;
          }
          .item-content {
            display: flex;
            flex-direction: column;
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              gap: 5px;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                color: #a0a0a0;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    // 亚马逊全链路数据
    .link-area {
      width: 100%;
      background-color: #f6f8fb;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 70px 0 20px 0;
      margin-top: 40px;
      .link-content {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 82px;
        .link-title {
          font-size: 48px;
          font-weight: 600;
          margin-bottom: 24px;
        }
        .link-introduce {
          font-size: 20px;
          color: rgb(102, 102, 102);
          margin-bottom: 60px;
        }
        img {
          width: 1300px;
          height: 686px;
        }
      }
    }
    // 服务区域
    .service-area {
      // border: 1px solid red;
      padding-top: 70px;
      padding-bottom: 100px;
      .title {
        font-size: 48px;
        text-align: center;
        margin-bottom: 15px;
      }
      .tip {
        text-align: center;
        color: #6f6f6f;
        font-size: 20px;
        margin-bottom: 57px;
      }
      .card-content {
        // border: 1px solid green;
        display: flex;
        flex-direction: column;
        gap: 23px;
        .top-card {
          display: flex;
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 15px;
          .card-item {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 13px 5px 10px 19px;
            background: url("../assets/img/bigBlue.png") no-repeat;
            background-size: 100% 100%;
            .item-title {
              color: rgb(255, 255, 255);
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 10px;
              letter-spacing: 2px;
            }
            .item-tip {
              color: rgb(255, 255, 255);
              font-size: 16px;
              margin-bottom: 15px;
              letter-spacing: 1px;
            }
            img {
              width: 85px;
              height: 88px;
              align-self: flex-end;
            }
          }
        }
        .bottom-card {
          display: flex;
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 23px;
          .card-bottom-item {
            width: 19%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 13px 5px 10px 19px;
            background: url("../assets/img/smallBlue.png") no-repeat;
            background-size: 100% 100%;
            .item-title {
              color: rgb(255, 255, 255);
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 10px;
              letter-spacing: 2px;
            }
            .item-tip {
              color: rgb(255, 255, 255);
              font-size: 16px;
              margin-bottom: 15px;
              letter-spacing: 1px;
            }
            img {
              width: 85px;
              height: 88px;
              align-self: flex-end;
            }
          }
        }
      }
    }
    // 结尾介绍
    .end-introduce {
      width: 100%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 95px 0 120px 0;
      background: url("../assets/img/color.png") no-repeat;
      background-size: 100% 100%;
      .text-content {
        box-sizing: border-box;
        width: 83%;
        display: flex;
        flex-direction: column;
        gap: 100px;
        .text-top {
          color: #474747;
          font-size: 48px;
          display: flex;
          justify-content: center;
          letter-spacing: 4px;
        }
        .button-content {
          width: 100%;
          display: flex;
          justify-content: center;
          .button-style {
            color: #fff;
            background-color: #4574de;
            border-radius: 25px;
            width: 150px;
            height: 50px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
          }
        }
      }
    }

    #b-p-0 {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      /* justify-content: center;  */
      align-items: center;
      .top-0 {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center;  */
        align-items: center;
        // 电脑区域
        .computer-container {
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          .computer-content {
            // border: 1px solid red;
            width: 72%;
            display: flex;
            justify-content: center;
            gap: 120px;
            box-sizing: border-box;
            padding-top: 100px;
            padding-bottom: 100px;
            .left {
              font-size: 46px;
              font-weight: 400;
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-grow: 1;
              flex-basis: 0;
              
              .title {
                font-size: 600;
                margin-bottom: 23px;
                letter-spacing: 2px;
                span {
                  color: #0240f7;
                }
              }
              .introduce {
                font-size: 18px;
                letter-spacing: 2px;
              }
              .button-style {
                margin-top: 145px;
                border-radius: 5px;
                background: rgb(51, 102, 255);
                color: #fff;
                width: 129px;
                cursor: pointer;
                height: 45px;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .right {
              flex-grow: 1;
              flex-basis: 0;
              img {
                width: 600px;
                height: 412px;
              }
            }
          }
        }
        // 介绍区域
        .introduce-area {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: -68px;
          // 底部
          .navigation-bottom {
            // border: 1px solid skyblue;
            display: flex;
            width: 73%;
            justify-content: space-between;
            padding: 30px 45px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            background-color: #fff;
            z-index: 10;
            .bottom-item {
              display: flex;
              flex-direction: flex;
              justify-content: space-around;
              align-items: center;
              color: #878889;
              gap: 20px;
              img {
                width: 55px;
                height: 55px;
              }
              .item-content {
                display: flex;
                flex-direction: column;
                gap: 5px;
                .item-text {
                  font-size: 32px;
                  color: #696a6b;
                  display: flex;
                  align-items: center;
                  span {
                    font-size: 36px;
                    font-weight: 600;
                    color: black;
                  }
                  .unit-text-content{
                    font-size: 20px;
                    font-weight: 500;
                    margin-left: 4px;
                  }
                }
                .item-introduce {
                  font-size: 20px;
                  color: rgb(102, 102, 102);
                }
              }
            }
          }
        }
      }
      .index-back {
        background: url("../assets/img/background.png") no-repeat;
        background-size: 100% 100%;
      }

      #b-part-0 {
        display: flex;
        margin-top: 30px;
        margin-bottom: 80px;
        width: 1260px;
        justify-content: space-between;

        /* flex-direction: row; */
        .sect-left {
          display: flex; /* 设置为 Flexbox 容器 */
          flex-direction: column; /* 垂直方向排列子元素 */
          justify-content: flex-end;
          align-items: flex-start;
          height: 400px;
          .title-1 {
            font-size: 52px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 32px;
          }
          .font-1 {
            color: #fff;
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 50px;
            margin-left: 16px;
            li {
              position: relative;
              height: 46px;
              line-height: 46px;
            }
          }
          :deep(.el-button--large) {
            height: 50px;
            padding: 12px 26px;
            font-size: 22px;
            font-weight: 600;
            background: linear-gradient(to right, rgb(6, 94, 247), #288cf0);
          }
        }
        .sect-right {
          width: 600px;
          display: flex;
          justify-content: right;
          img {
            width: 600px;
          }
        }
      }
    }

    #b-p-1 {
      width: 100%;
      height: auto;
      #b-part-1 {
        display: flex;
        flex-direction: column;
        /* position: relative; */
        width: 100%;
        align-items: center;

        .center-title {
          padding: 0 0 50px 0;
          .center1-top {
            color: #333744;
            font-size: 36px;
            font-family: "Microsoft YaHei-Bold";
            padding-top: 70px;
            font-weight: bold;
            text-align: center;
          }
          .center1-top1 {
            margin-top: 20px;
            color: #6b7183;
            font-size: 16px;
            font-family: "Microsoft YaHei";
            text-align: center;
          }
        }
        .center1-1 {
          width: 1428px;
          height: 580px;
          margin: 0 auto;
        }
        .center1-header {
          /* float: left; */
          div {
            display: block;
            unicode-bidi: isolate;
          }
          .fist {
            margin-left: 40px;
          }
          li {
            display: list-item;
            text-align: -webkit-match-parent;
            unicode-bidi: isolate;
          }
          li {
            float: left;
            text-align: center;
            margin-top: 60px;
          }
        }
        .jiantou {
          width: 226px;
          height: 200px;
          float: left;
          text-align: center;
        }
        .jiantou2 {
          width: 18px;
          height: 68px;
          float: right;
          margin-top: 222px;
          margin-right: 82px;
        }
        .jiantou > img {
          margin-top: 120px;
        }
        fieldset,
        img {
          border: none;
        }
        img {
          overflow-clip-margin: content-box;
          overflow: clip;
        }
        ul {
          display: block;
          list-style-type: disc;
          /* margin-block-start: 1em;
            margin-block-end: 1em; */
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
          unicode-bidi: isolate;
        }
        ul,
        ol {
          list-style: none;
        }
      }
    }

    #b-p-2 {
      width: 100%;
      height: auto;
      .center1-header {
        display: flex;
        flex-wrap: wrap;
        /* float: left; */
        width: 1251px;
      }
      .center-title {
        padding: 0 0 60px 0;
        .center1-top {
          color: #333744;
          font-size: 36px;
          font-family: "Microsoft YaHei-Bold";
          padding-top: 50px;
          /* font-weight: bold; */
          text-align: center;
        }
        .center1-top1 {
          margin-top: 14px;
          color: #6b7183;
          font-size: 16px;
          font-family: "Microsoft YaHei";
          text-align: center;
        }
      }
      ul {
        display: block;
        list-style-type: disc;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        /* padding-inline-start: 40px; */
        unicode-bidi: isolate;
      }
      ul {
        li {
          display: list-item;
          text-align: -webkit-match-parent;
          unicode-bidi: isolate;
          margin-right: -5px;
        }
        li {
          float: left;
          text-align: center;
        }
        .margin-0 {
          margin-top: 60px;
        }
      }
      ul {
        list-style: none;
      }
      .center1-1 {
        width: 1251px;
        height: 530px;
        margin: 0 auto;

        ._文本段落 {
          text-align: left;
        }
        .u17 {
          width: 420px;
          display: flex;
          justify-content: center;
        }
        .u18_div {
          display: flex;
          justify-content: flex-start;
          border-width: 0px;
          width: 240px;
          height: 88px;
          background: inherit;
          background-color: rgba(255, 255, 255, 0);
          border: none;
          border-radius: 0px;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          line-height: 28px;
          .u19_img {
            height: 70px;
          }
        }
        .text {
          align-self: flex-start;
          justify-self: flex-start;
          padding: 0px 0px 0px 0px;
          box-sizing: border-box;
          width: 240px;
          padding-left: 10px;
        }
        .ax_default {
          font-family: "Arial Normal", "Arial";
          font-weight: 400;
          font-style: normal;
          /* font-size: 13px; */
          letter-spacing: normal;
          color: #333333;
          vertical-align: none;
          text-align: left;
          line-height: normal;
          text-transform: none;
        }
      }
    }
    .b-p-3 {
      width: 100%;
      display: flex;
      justify-content: center;
      .s-body {
        padding-left: 20px;
        margin-top: 90px;
        width: 1230px;
        /* height: 500px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left-top-1 {
          margin-top: 40px;
        }
        .left-top-2 {
          margin-top: 0px;
          margin-bottom: 20px;
        }
        .s-left {
          height: 420px;
          .title-2 {
            font-size: 36px;
            color: #333;
            font-weight: 400;
            margin-bottom: 13px;
          }
          .font-2 {
            color: #333;
            font-size: 14px;
            width: 480px;
            li {
              /* position: relative; */
              margin-top: 20px;
              height: 24px;
              line-height: 24px;
            }
          }
          .font-3 {
            color: #666;
          }
        }
        .s-right {
          .u19_img {
            height: 400px;
          }
          .u19_img0 {
            height: 330px;
          }
        }
        .product-list {
          margin-top: 30px;
          .product-tip{
            width: 588px;
            white-space: normal;
            color: #666666;
          }
          .font-2 {
            display: flex;
            flex-direction: column;
            gap: 22px;
            margin-top: 30px;
            .product-item {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #666666;
            }
          }
        }
      }
    }
    .b-p-4 {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: rgba(246, 248, 251, 1);
      .s-body {
        padding-left: 20px;
        margin-top: 90px;
        width: 1230px;
        /* height: 600px; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .s-left {
          .u19_img {
            height: 400px;
          }
          .u19_img0 {
            height: 380px;
          }
        }
        .s-right {
          margin-top: 50px;
          height: 420px;
          .title-2 {
            font-size: 36px;
            color: #333;
            font-weight: 400;
            margin-bottom: 13px;
          }
          .font-2 {
            color: #333;
            font-size: 14px;
            width: 470px;
            li {
              /* position: relative; */
              margin-top: 20px;
              height: 24px;
              line-height: 24px;
            }
          }
          .font-3 {
            color: #666;
          }
        }
        .product-list {
          .product-tip{
            width: 588px;
            white-space: normal;
            color: #666666;
          }
          .font-2 {
            display: flex;
            flex-direction: column;
            gap: 22px;
            margin-top: 30px;
            .product-item {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #666666;
            }
          }
        }
      }
    }
    .center3 {
      /* width: 1200px; */
      height: 580px;
      background-color: skyblue;
      margin: 0 auto;
      background-image: url(@/assets/images/intro/group52.png);

      .center3-top {
        width: 300px;
        margin: 0 auto;
        color: #ffffff;
        font-size: 30px;
        font-family: "Microsoft YaHei-Bold";
        padding-top: 50px;
        font-weight: bold;
      }
      .center3-top1 {
        width: 200px;
        margin: 0 auto;
        margin-top: 20px;
        color: #6b7183;
        font-size: 16px;
        font-family: "Microsoft YaHei";
      }
      .center3-img > img {
        width: 126px;
        margin-top: 60px;
      }
      .center3-img {
        width: 235px;
        height: 210px;
      }
      .center3-p {
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        color: #969db3;
      }
      .center3-p p {
        line-height: 18px;
        margin-left: 14px;
        margin-right: 14px;
      }
      .center3-p1 {
        font-family: "Microsoft YaHei";
        font-size: 16px;
        margin-bottom: 15px;
        color: #2e313d;
        font-weight: 600;
      }
    }
    .center3-center {
      width: 1274px;
      height: 350px;
      margin: 0 auto;
      margin-top: 30px;
      li {
        width: 235px;
        height: 350px;
        background-color: #ffffff;
        float: left;
        text-align: center;
      }
      .mar-0 {
        margin-left: 23px;
      }
    }

    .wrapPc {
      min-width: 1260px;
      display: block;
      .footer-div {
        margin: 0 auto;
        background-color: #213360;
        padding-bottom: 46px;
        position: relative;
        z-index: 999;
      }
      .footer {
        margin: 0 auto;
        overflow: hidden;
        padding-top: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 1260px;
      }
      .footerLogo {
        margin-top: 14px;
        margin-left: 100px;
        width: 480px;
        display: flex;
        flex-direction: row;
        .logo-3 {
          height: 44px;
        }
      }
      .footerLogo p {
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 26px;
        color: #fff;
      }
      .top10 {
        margin-top: 10px;
      }
      .top11 {
        margin-top: 12px;
        margin-left: 30px;
        font-size: 24px;
      }
      .left10 {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
      }
      .left11 {
        font-size: 12px;
        color: #fff;
        font-weight: 400;
        height: 20px;
      }
      .top-0 {
        margin-top: 6px;
      }
      .overflow {
        overflow: hidden;
      }
      ul,
      ol {
        list-style: none;
      }
    }
    // 价格区域
    .price-area {
      width: 100%;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      padding: 90px 0;
      .select-container {
        width: 70%;
        // border: 1px solid green;
        .title {
          font-size: 40px;
          text-align: center;
          margin-bottom: 15px;
          letter-spacing: 2px;
        }
        .tip {
          font-size: 18px;
          text-align: center;
          color: rgb(102, 102, 102);
          margin-bottom: 50px;
          letter-spacing: 1px;
        }
        .card-content {
          display: flex;
          gap: 35px;
          .card-item {
            flex-grow: 1;
            flex-basis: 0;
            border-radius: 20px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            background: rgb(255, 255, 255);
            padding: 30px 0;
            cursor: pointer;
            transition: transform 0.5s ease; /* 添加过渡效果，持续时间为 2 秒 */
            &:hover {
              transform: scale(1.05);
            }
            .title {
              font-size: 34px;
              font-weight: 600;
            }
            .img-content {
              display: flex;
              justify-content: center;
              margin: 12px 0;
              .item-img {
                width: 66px;
                height: 66px;
              }
            }
            .month {
              color: rgb(255, 16, 16);
              font-size: 25px;
              text-align: center;
              .month-currency {
                position: relative;
                top: -10px;
              }
              .month-price {
                font-size: 36px;
                margin-right: 5px;
                font-weight: 600;
              }
              .month-unit {
                color: #6f6f6f;
              }
            }
            .content {
              margin-top: 33px;
              .content-item {
                text-align: center;
                margin-bottom: 15px;
                color: #6f6f6f;
                img {
                  width: 12px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
    // 常见问题
    .problem-area {
      width: 100%;
      // border: 1px solid red;
      background: rgb(246, 248, 251);
      padding-bottom: 140px;
      padding-top: 90px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        text-align: center;
        font-size: 48px;
        margin-bottom: 60px;
      }
      .content {
        display: flex;
        width: 100%;
        flex-direction: column;
        .top {
          display: flex;
          justify-content: center;
          gap: 90px;
          .left-one {
            margin-bottom: 40px;
          }
          .right-two {
            margin-bottom: 40px;
          }
          .one-title,
          .two-title {
            color: rgb(51, 51, 51);
            font-size: 24px;
            margin-bottom: 10px;
          }
          .one-container,
          .two-container {
            width: 520px;
            white-space: normal;
            // word-wrap: break-word; /* 允许在单词内换行 */
            text-align: justify; /* 左右对齐 */
            color: #6f6f6f;
            letter-spacing: 1px;
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          gap: 90px;
          .three-title,
          .four-title {
            color: rgb(51, 51, 51);
            font-size: 24px;
            margin-bottom: 10px;
          }
          .three-container,
          .four-container {
            width: 520px;
            white-space: normal;
            word-wrap: break-word; /* 允许在单词内换行 */
            text-align: justify; /* 左右对齐 */
            color: #6f6f6f;
            letter-spacing: 1px;
          }
        }
      }
    }
    // 底部区域
    .bottom-area {
      width: 100%;
      background: rgb(30, 45, 64);
      display: flex;
      justify-content: center;
      padding-top: 35px;
      padding-bottom: 25px;
      .bottom-container {
        width: 75%;
        display: flex;
        gap: 40px;
        // 左边
        .left {
          flex-grow: 2;
          flex-basis: 0;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          gap: 30px;
          .left-top {
            display: flex;
            align-items: center;
            gap: 30px;
            img {
              width: 100px;
              height: 40px;
            }
            .left-top-text {
              color: rgb(255, 255, 255);
              font-size: 20px;
            }
          }
          .left-bottom {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: rgb(255, 255, 255);
          }
        }
        .right {
          flex-grow: 3;
          // border: 1px solid green;
          flex-basis: 0;
          .right-content {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            .right-item {
              .item-nav {
                color: #fff;
                cursor: pointer;
                font-size: 20px;
                width: 120px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgb(102, 102, 102);
              }
              .item-content {
                margin-top: 18px;
                .item-text {
                  color: #848b95;
                  margin-bottom: 12px;
                  cursor: pointer;
                  &:hover{
                    color: white;
                  }
                }
              }
              .activeNav {
                border-bottom: 1px solid #4792e0;
              }
            }
          }
        }
      }
    }

    .vipService {
      .banner {
        width: 100%;
        .banner-bg {
          width: 100%;
          height: 650px;
          margin: 0 auto;
          /* margin-top: 70px; */
          /* background-color: #f6f7fc; */
          position: relative;
        }
        .banner-left {
          position: absolute;
          width: 500px;
          height: 190px;
          font-family: "Microsoft YaHei";
          font-size: 32px;
          color: #fffefe;
          font-weight: bold;
          top: 66px;
          margin-left: 25%;
          .text-b {
            margin-top: 15px;
            span {
              color: #4792e0;
            }
          }
          .regsiter-a {
            width: 120px;
            height: 40px;
            border-radius: 20px;
            background-color: #4792e0;
            color: #ffffff;
            font-family: "Microsoft YaHei";
            font-size: 18px;
            margin-top: 30px;
            line-height: 40px;
            text-align: center;
            font-weight: normal;
            cursor: pointer;
          }
        }
        .banner-bottom {
          position: absolute;
          width: 1200px;
          height: 280px;
          background-color: #fff;
          border: 1px solid #d9dde9;
          left: 50%;
          margin-left: -600px;
          top: 330px;
          ul,
          ol {
            list-style: none;
          }
          .goodsDetail {
            float: left;
            width: 299px;
            height: 260px;
            text-align: center;
            margin-top: 8px;
            font-family: "Microsoft YaHei";
            border-right: 1px solid #edeff6;
            .text-c {
              font-size: 22px;
              color: #2e313d;
              margin-top: 20px;
            }
            .text-d {
              font-size: 14px;
              color: #6b7183;
              margin-top: 5px;
            }
            .text-e {
              font-size: 26px;
              color: #333744;
              margin-top: 30px;
            }
          }
        }
      }
      .tit {
        width: 100%;
        height: 80px;
        margin: 0 auto;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 30px;
        color: #2e313d;
        line-height: 80px;
      }
      .con {
        width: 100%;
        .content {
          width: 100%;
          background-color: #f7f8fc;
          margin: 0 auto;
          padding-top: 30px;
          padding-bottom: 30px;
          .tab {
            width: 100%;
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            align-items: center;
            .row-a {
              width: 1200px;
              height: 50px;
              /* margin: 0 auto; */
              display: flex;
              flex-direction: row;
              .a-cell1 {
                width: 449px;
                height: 50px;
                border: 1px solid #d9dde9;
                border-bottom: none;
                border-right: none;
                background-color: #f2f4fb;
                font-family: "Microsoft YaHei";
                font-size: 18px;
                color: #2e313d;
                float: left;
                text-align: center;
                line-height: 50px;
              }
              .a-cell {
                /* width: 186px; */
                flex: 1;
                height: 50px;
                border: 1px solid #d9dde9;
                border-bottom: none;
                border-right: none;
                background-color: #f2f4fb;
                font-family: "Microsoft YaHei";
                font-size: 18px;
                color: #2e313d;
                float: left;
                text-align: center;
                line-height: 50px;
              }
            }
            .table {
              width: 1200px;
              height: 50px;
              /* margin: 0 auto; */
            }
            table {
              /* border-collapse: collapse; */
              border-spacing: 0;
            }
            .row-b {
              width: 1200px;
              height: 40px;
            }
            .b-cell {
              width: 184px;
              height: 40px;
              font-family: "Microsoft YaHei";
              font-size: 14px;
              color: #6b7183;
              border: 1px solid #d9dde9;
            }
          }
        }
      }
    }
    .abt {
      .banner {
        width: 100%;
        /* margin-bottom:60px; */
        .banner-bg {
          width: 100%;
          height: 510px;
          /* margin: 0 auto; */
          /* margin-top: 70px; */
          /* background-color: #fcf9f6; */
          /* position: relative; */
          display: flex;
          /* flex-direction: column; */
          justify-content: center;
        }
        .banner-0 {
          width: 1000px;
          height: 190px;
          font-family: "Microsoft YaHei";
          color: #fffefe;
          top: 66px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 200px;
          .text-a {
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 2px;
          }
          .text-b {
            margin-top: 15px;
            font-size: 28px;
            letter-spacing: 2px;
          }
        }
      }
      .p-0 {
        width: 100%;
        .box-0 {
          /* margin-left:140px; */
          width: 100%;
          height: 200px;
          color: #333;
          display: flex;
          flex-direction: column;
          align-items: center;
          .tit-0 {
            width: 1240px;
            font-size: 38px;
            margin-bottom: 14px;
            letter-spacing: 2px;
          }
          .text-0 {
            width: 1240px;
            font-size: 14px;
            line-height: 30px;
          }
        }
        .img-0 {
          width: 100%;
          display: flex;
          justify-content: center;
          .logo-3 {
            width: 1240px;
            border-radius: 16px; /* 圆形 */
            /* object-fit: cover;  */
          }
        }
        .top-0 {
          margin-top: 110px;
        }
        .top-1 {
          margin-top: 70px;
        }
        .top-2 {
          margin-top: 14px;
          margin-bottom: 110px;
        }
      }
    }

    .b-color-0 {
      background-color: bisque;
    }
    .b-color-1 {
      background-color: gainsboro;
    }
  }

  .font-size-0 {
    font-size: 16px !important;
  }
}

.s-0 {
  background-color: white;
  // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  // border: 1px solid green;
  display: flex;
  justify-content: center;
}
.s-1 {
  // background-color: transparent;
  // background: linear-gradient(
  //   90deg,
  //   rgb(220, 231, 255),
  //   rgb(225, 238, 255) 53.581%,
  //   rgb(223, 237, 255) 104.245%
  // );
  opacity: 0.8;
  // border: 1px solid red;
  display: flex;
  justify-content: center;
}
.index-header {
  width: 72%;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
.header {
  // position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  --tw-bg-opacity: 1;
  /* box-shadow: 0 2px 10px 0 rgba(180, 189, 221, .25); */

  .nav-logo {
    display: inline-block;
    // margin-right: 100px;
    .logo-0 {
      height: 44px;
    }
    .logo-1 {
      height: 44px;
    }
  }
  .nav {
    width: 540px;
    // margin-right: 289px;
  }
  .function-area {
    // margin-left: 90px;
    cursor: pointer;
    .font-0 {
      width: 117px;
      height: 42px;
      border-radius: 20%;
      color: #ffffff;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(51, 102, 255);
    }
    .font-1 {
      width: 117px;
      height: 42px;
      border-radius: 20%;
      color: #ffffff;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(51, 102, 255);
    }
  }
}

:deep(.tab.active) {
  color: #fff;
}
</style>
